import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ServiceBanner from '../../components/ServiceBanner';

export default function VirtualConsultation() {
    return (
        <>
            <Header />
           
            <ServiceBanner/>
            <Footer />
        </>
    );
}